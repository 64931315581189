<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col :cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          :tableName="tableName"
                          base-table="customer-contracts"
                          columnsPrefix="customer-contract.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.title.customer-contract"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >

                    <template #cell(id)="row">
                        {{ row.item.id }}
                        <b-button size="sm"
                                  @click="customerContractUrl(row.item.file)"
                                  variant="info"
                                  class="ml-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </template>
                    <template #cell(kunden_id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.kunden_id.id}, refreshTable)">
                            {{ row.item.kunden_id.name }}
                        </a>
                    </template>
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="customerContractUrl(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template #cell(mails)="row">
                        <div v-for="mail in row.item.mails"
                             :key="mail.id"
                        >
                            {{ mail.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                            <span v-if="mail.is_send">
                            ({{ $t('common.label.send') }})
                        </span>
                            <span v-else>
                            ({{ $t('common.label.not_send') }})
                </span>
                        </div>
                    </template>
                    <template #cell(options)="row">
                        <multiselect
                            v-model="row.item.docs_option"
                            :options="getDocumentTypes()"
                            :multiple="true"
                            @input="changeDocs($event, row.item.id)"
                        >
                        </multiselect>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item @click="sendMail(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item._delete"
                                             @click="deleteContract(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.button.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {contract_id: row.item.id, customer_id: row.item.kunden_id.id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="downloadCustomerContractUrl(row.item.file)">
                                {{ $t('common.button.download') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'contracts',
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
        offeringId: {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
            source: null,
            currentPreview: null,
            tableName: 'single-customer-contracts',
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CustomerContract', ['getContractsTable']),
        ...mapGetters('CommonData', ['getDocumentTypes']),
        shown() {
            this.loading = false
        },
        ctxBaseParams() {
            return {
                'tableName': this.tableName,
            }
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CustomerContract/fetchSingleContracts', 'getContractsTable', {}, (tableData) => {
                this.params = tableData.params
                this.allItems.forEach(contract => {
                    contract.docs_option = [];
                    if (contract.options && contract.options.docs) {
                        contract.docs_option = contract.options.docs
                    }
                });
            });
        },
        ctxAdditionalParams() {
            ;let params = {
                'kunden_id': this.customerId
            };
            if (typeof this.offeringId != 'undefined') {
                params['offering_id'] = this.offeringId
            }
            return params
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.createCustomerContract'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('contract', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('customer-contract-modal', {
                            customerId: this.customerId,
                            offeringId: this.offeringId ?? null
                        }, this.refreshTable)
                    },
                }
            ]
        },
        customerContractUrl(file) {
            this.$root.openDocument(file, 'customer_contracts')
        },
        downloadCustomerContractUrl(file) {
            this.$root.downloadDocument(file, 'customer_contracts')
        },
        deleteContract(id) {
            this.$removeConfirm('CustomerContract/deleteContract', {
                contractId: id
            }, this.refreshTable)
        },
        changeDocs: function (event, id) {
            Promise.all([this.$store.dispatch('CustomerContract/setDocsCustomerContract', {
                docs: event,
                id: id
            })]).then(() => {
                this.refreshTable
            })
        },
        sendMail(id) {
            this.$root.$children[0].openModal('email-modal', {
                documentId: id,
                docType: 'contract'
            }, this.refreshTable, {})
        },
        fixedDate(date) {
            return Math.round(Date.parse(date) / 1000);
        }
    }
}
</script>