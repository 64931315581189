<template>
    <zw-input-group v-model="inputVal.count"
                    name="count"
                    disable-label
                    type="number"
                    size="sm"
                    :readonly="readOnly"
                    @change="()=>{savePosition('count',inputVal);returnFocus()}"
                    @keyup.enter="returnFocus()"
    ></zw-input-group>
</template>

<script>
export default {
    name: 'CountColumn',
    props: {
        value: [String, Number, Object],
        savePosition: [Function],
        readOnly: [Boolean]
    },
    methods: {
        returnFocus() {
            document.querySelector('.position-search-field input[type="text"]').focus()
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>