<template>
    <b-col cols="4">
        <b-form inline>
            <label class="mr-sm-2">{{ $t('offering.label.fulfilment_status') }}</label>
            <zw-select-group v-model="inputVal"
                             :options="getFulfilmentStatuses()"
                             name="fulfilment_status"
                             validate="required"
                             disable-label
                             :readonly="readonly && !partialReadonly"
                             style="max-width: 250px"
            ></zw-select-group>
            <b-button v-if="!readonly|| partialReadonly" @click="updateFulfilmentStatus"
                      variant="success"
                      class="ml-2"
            >
                <font-awesome-icon icon="save"></font-awesome-icon>
            </b-button>
        </b-form>
    </b-col>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from "vuex";

export default {
    name: 'OfferingGeneralStatus',
    mixins: [commonSave],
    props: {
        'value': [String],
        'offeringId': [Number],
        'readonly': [Boolean],
        'partialReadonly': [Boolean],
    },
    data() {
        return {
            loading: true,
            fulfilment_status: null,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getFulfilmentStatuses']),
        updateFulfilmentStatus() {
            this.$store.dispatch('Offering/updateFulfilmentStatus', {
                id: this.offeringId,
                fulfilment_status: this.inputVal
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>