<template>
    <div>
        <b-row>
            <b-col cols="4">
                <zw-select-group v-model="selected_payment_method"
                                 :options="getPaymentMethods()"
                                 name="payment_method"
                                 :label-prefix="labelPrefix"
                                 @change="updatePaymentMethod"
                ></zw-select-group>
            </b-col>
            <b-col cols="4">
                <zw-select-group v-model="selected_payment_status"
                                 :options="getPaymentStatuses()"
                                 name="payment_status"
                                 :label-prefix="labelPrefix"
                                 @change="updatePaymentStatus"
                                 :help-text="(payment_status ? payment_status.updated_at : null) | formatUpdatedDate('DD.MM.YYYY HH:mm:ss')"
                ></zw-select-group>
            </b-col>
            <b-col cols="4" v-for="invoice in entityStatuses" :key="invoice.id">
                <zw-select-group v-model="invoice.status_id"
                                 :options="getInvoiceStatuses()"
                                 name="status"
                                 :label="$t((labelPrefix ? labelPrefix : 'common.label.') + 'invoice_status')+' '+invoice.number"
                                 @change="(val)=>{updateInvoiceStatus(val,invoice.id)}"
                                 :help-text="invoice.updated_at | formatUpdatedDate('DD.MM.YYYY HH:mm:ss')"
                ></zw-select-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="4">
                <zw-date-group v-model="selected_payment_date"
                               name="payment_date"
                               :label-prefix="labelPrefix"
                ></zw-date-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <h4>{{ $t('common.form.title.offeringBankTransactions') }}

                    <b-button
                        @click="$root.$children[0].openModal('events-modal', {id: offering_id,events:['offering_transaction_assigned','offering_transaction_un_assigned'],type:'offering'})"
                    >
                        <font-awesome-icon class="mr-2" icon="calendar"/>
                        {{ $t('common.button.events') }}
                    </b-button>
                </h4>
            </b-col>
            <div v-show="allItems.length==0">
                <b-col cols="12">
                    <b-button variant="success"
                              @click="$root.$children[0].openModal('assign-credit-bank-transfer-modal', {'offering_id': offering_id},refreshTable)"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('bank.button.assign_bank_transfer') }}
                    </b-button>
                </b-col>
            </div>
            <div v-show="allItems.length>0">
                <zw-table ref="table"
                          tableName="offering_bank_transactions"
                          base-table="bank_transactions"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-card
                            border-variant="info"
                            align="center"
                            class="zero_padding_card_body bg-light"
                        >
                            <b-card-text>
                                <b-row>
                                    <b-col cols="6">
                                        <b-row>
                                            <b-col class="text-right" cols="6">
                                                {{ $t('columns.offering.netto') }}:
                                            </b-col>
                                            <b-col class="text-left" cols="6">
                                                {{ offering.netto | priceFormat }} &euro;
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-right" cols="6">
                                                {{ $t('columns.offering.brutto') }}:
                                            </b-col>
                                            <b-col class="text-left" cols="6">
                                                {{ offering.brutto | priceFormat }} &euro;
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-row>
                                            <b-col class="text-right" cols="6">
                                                {{ $t('columns.offering.paid') }}:
                                            </b-col>
                                            <b-col class="text-left" cols="6">
                                                {{ totalPaid | priceFormat }} &euro;
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-right" cols="6">
                                                {{ $t('columns.offering.diff') }}:
                                            </b-col>
                                            <b-col class="text-left" cols="6"
                                                   :class="((offering.brutto - totalPaid) > 0) ? 'text-danger':'text-success'">
                                                {{ (offering.brutto - totalPaid) | priceFormat }} &euro;
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>
                            <b-dropdown-item
                                v-if="row.item.assigned"
                                @click="unlink(row.item.id)"
                            >
                                {{ $t('bank.actions.unlink') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </div>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'OfferingPaymentInfo',
    mixins: [commonSave, commonTable],
    props: {
        'offering_id': {
            type: [String, Number],
            required: true
        },
        'offering': {
            type: Object
        },
        'payment_method': {
            type: [String],
        },
        'payment_status': {
            type: [Object],
        },
        'payment_date': {},
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.label.',
            selected_payment_method: '',
            selected_payment_status: null,
            selected_payment_date: null,
            entityStatuses: [],
            totalPaid: 0,
            saveFilters: false,
            tableUniqKey: 'offering-payments',
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Offering', ['getPaymentMethods', 'getBankTransactions', 'getBankTransactionsTable']),
        ...mapGetters('Invoice', ['getInvoiceStatuses']),
        ...mapGetters('CommonData', ['getEntityStatuses']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Offering/fetchBankTransactionsTable', 'getBankTransactionsTable', {}, (tableData) => {
                this.totalPaid = 0
                this.allItems.forEach(item => {
                    this.totalPaid += parseFloat(item.amount);
                })
            });
        },
        ctxBaseParams() {
            return {
                'offering_id': this.offering_id,
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('bank.button.assign_bank_transfer'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('assign-credit-bank-transfer-modal', {
                            'offering_id': this.offering_id
                        }, this.refreshTable)
                    }
                }
            ]
        },
        shown() {
            this.offering.allinvoices.forEach(invoice => {
                this.entityStatuses.push({
                    'id': invoice.id,
                    'number': invoice.number,
                    'status_id': invoice.entity_status ? invoice.entity_status.status_id : 0,
                    'updated_at': invoice.entity_status ? invoice.entity_status.updated_at : null
                })
            })
            this.loading = true
            this.selected_payment_method = this.payment_method
            this.selected_payment_status = this.payment_status ? this.payment_status.status_id : null
            this.selected_payment_date = this.payment_date
            const paymentMethodsFetch = this.$store.dispatch('Offering/fetchPaymentMethods', this.offering_id)
            const invoiceStatusesFetch = this.$store.dispatch('Invoice/fetchInvoiceStatuses', this.offering_id)

            Promise.all([paymentMethodsFetch, invoiceStatusesFetch])
                .finally(() => {
                    this.loading = false;
                })
        },
        updateInvoiceStatus(status_id, id) {
            this.$store.dispatch('Invoice/updateStatus', {
                id: id,
                statusId: status_id
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
        updatePaymentStatus(status_id) {
            this.$store.dispatch('Offering/updatePaymentStatus', {
                id: this.offering_id,
                paymentStatus: status_id
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
        updatePaymentDate(value) {
            this.$store.dispatch('Offering/updatePaymentDate', {
                id: this.offering_id,
                date: value
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
        getPaymentStatuses() {
            const paymentStatuses = this.getEntityStatuses()['payment'] || []
            let list = {}
            paymentStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        },
        updatePaymentMethod(val) {
            this.$store.dispatch('Offering/updatePaymentMethod', {
                id: this.offering_id,
                paymentMethod: val
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlink', {id}).finally(() => {
                this.refreshTable()
            })
        },
    },
    watch: {
        selected_payment_date: function (val) {
            this.updatePaymentDate(val)
        },
    }
}
</script>