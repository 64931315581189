<template>
    <div>
        <zw-table ref="table"
                  tableName="offering_notices"
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :selectedSource="selectedSource"
                  :actions-list="getActions()"
                  :sticky="false"
                  sticky-header="100%"
        >
            <template #cell(user_id)="row">
                {{ row.item.user_email }}
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'OfferingNotices',
    mixins: [commonSave, commonTable],
    props: {
        'offering_id': {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Offering', ['getNotices']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Offering/fetchNotices', 'getNotices');
        },
        ctxAdditionalParams() {
            return {
                'kva_id':  this.offering_id
            }
        },
        getActions() {
            return []
        },
    },
}
</script>