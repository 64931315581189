<template>

    <draggable :class="{[`cursor-grabbing`]: dragging === true}"
               v-model="customGroups"
               @change="draggedCustomGroup"
               group="columns"
               @start="dragging = true"
               @end="dragging = false"
               tag="div"
               handle=".card-header"
    >
        <b-card class="mt-3 zero_padding_card_body"
                v-for="customGroup in customGroups"
                v-if="fields"
        >
            <b-card-header header-tag="header" class="p-0 m-0" role="tab">
                <b-button block v-b-toggle="'accordion-' + customGroup" class="btn-light">
                    {{ customGroup == 'empty' ? $t('common.group.custom_fields') : customGroup }}
                </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-'+customGroup"
                        visible
                        :accordion="'custom-accordion-'+customGroup"
                        role="tabpanel"
            >
                <b-card-text class="p-3">
                    <b-row>
                        <b-col v-for="field in fields"
                               :key="field.name"
                               v-if="showField(field, customGroup)"
                               sm="3"
                        >
                            <custom-field v-model="inputVal[field.name]"
                                          :model="form"
                                          :minDate="field.options.actual_dates == 1 ? currentDate() : ''"
                                          :readonly="field.options.readonly == 1 ? true : false"
                                          :isRequired="field.options.required == 1 ? true : false"
                                          :field="field"
                            />
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-collapse>
        </b-card>
    </draggable>
</template>

<script>

import {mapGetters} from "vuex";
import Draggable from "vuedraggable";
import moment from 'moment'

export default {
    components: {
        Draggable
    },
    props: {
        'model': {
            type: String,
            required: true
        },
        'fields': [Object, Array],
        'value': [Object],
        'form': [Object],
    },
    data() {
        return {
            customGroups: [],
            dragging: false,
        }
    },
    mounted() {
        this.formatCustomGroups()
    },
    methods: {
        ...mapGetters('CommonData', ['getMe']),
        showField(field, customGroup) {
            let onlyFor = field.options.only_for || []
            let onlyForIncluded = onlyFor.find(item=>{
                return this.form.customerType.includes(item)
            })

            return field.options.group!='hidden'
                && field.options.main_field != 1
                && ((!field.options.group && customGroup == 'empty') || (field.options.group && field.options.group == customGroup))
                && (onlyFor.length==0 || (onlyFor.length && onlyForIncluded))
        },
        draggedCustomGroup() {
            axios.post(window.apiUrl + '/save-custom-groups', {
                customGroups: this.customGroups,
                model: this.model,
            }, {'skip_loading': true});
            this.$store.dispatch('CommonData/fetchCommonData')
        },
        formatCustomGroups() {
            let userCustomGroups = (this.getMe().custom_groups && this.getMe().custom_groups[this.model]) ?? []
            Object.entries(this.fields).forEach(([key, value]) => {
                value.options.group = value.options.group ?? 'empty'
                if (value.options.group && value.options.group != 'hidden' && !userCustomGroups.includes(value.options.group)) {
                    userCustomGroups.push(value.options.group)
                }
            });

            this.customGroups = userCustomGroups
       },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
    },
    watch: {
        'fields': {
            handler: function () {
                this.formatCustomGroups()
            },
            deep: true
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>