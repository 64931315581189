<template>
    <b-col cols="5">
        <b-form inline>
            <b-row>
                <b-col cols="10">
                    <label class="mr-sm-2 d-none d-lg-inline">{{ $t('offering.label.status') }}</label>
                    <zw-select-group v-model="inputVal"
                                     :options="getAllowedStatuses() | optionsStatuses"
                                     name="status"
                                     validate="required"
                                     disable-label
                                     :readonly="false"
                                     style="max-width: 250px; display: inline-block;"
                                     size="m"
                                     @change="updateStatus"
                    ></zw-select-group>
                </b-col>
<!--                <b-col cols="2">-->
<!--                    <b-button v-if="!false" @click="updateStatus"-->
<!--                              variant="success"-->
<!--                              class="ml-2"-->
<!--                    >-->
<!--                        <font-awesome-icon icon="save"></font-awesome-icon>-->
<!--                    </b-button>-->
<!--                </b-col>-->
            </b-row>
        </b-form>
    </b-col>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from "vuex";

export default {
    name: 'OfferingGeneralStatus',
    mixins: [commonSave],
    props: {
        'value': [String],
        'offeringId': [Number],
        'readonly': [Boolean],
        'partialReadonly': [Boolean],
    },
    data() {
        return {
            loading: true,
            status: null,
        }
    },
    mounted() {
        const statusesFetch = this.$store.dispatch('Status/fetchStatuses')
    },
    methods: {
        ...mapGetters('Status', ['getStatuses']),
        // ...mapGetters('CommonData', ['getStatuses']),
        getAllowedStatuses() {
            let allowed = {}
            const currentStatus = this.getStatuses().find(status => status.value == this.inputVal) || null
            if (currentStatus) {
                this.getStatuses().forEach(status => {
                    if (currentStatus.id == status.id || currentStatus.change_to_statuses.includes(status.id)) {
                        allowed[status.value] = status.name
                    }
                })
            } else {
                this.getStatuses().forEach(status => {
                    allowed[status.value] = status.name
                })
            }

            return allowed
        },
        updateStatus() {
            this.$store.dispatch('Offering/updateStatus', {
                id: this.offeringId,
                status: this.inputVal
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>