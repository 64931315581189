<template>
    <div v-if="!loading">
        <b-row>
            <b-col cols="4">
                <zw-select-group v-model="form.address_type"
                                 name="address_type"
                                 :options="addressTypeOptions"
                                 :label-prefix="labelPrefix"
                ></zw-select-group>
            </b-col>
            <b-col cols="4">
                <zw-select-group v-model="form.formality"
                                 :options="getFormality()"
                                 name="formality"
                                 :label-prefix="labelPrefix"
                ></zw-select-group>
            </b-col>
            <b-col cols="4" v-if="formalityFormat">
                <b-form-group :label="$t(labelPrefix+'formality_format')">
                    <strong>{{ formalityFormat }}</strong>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="type=='confirm'">
            <b-col cols="4">
                <zw-switch-group v-model="form.options.include_positions"
                                 name="include_positions"
                                 :label-prefix="labelPrefix"
                ></zw-switch-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group :label="$t(labelPrefix+'header')">
                    <b-form-select v-model="form.header_id"
                                   :options="headerOptions"
                                   class="mb-3"
                                   @change="headerSelected"
                                   size="lg"
                    ></b-form-select>

                    <div class="mb-3">
                        <b-row>
                            <b-col cols="6"
                                   v-for="(placeholders, placeholderType) in getPlaceholders()"
                                   :key="'type_'+placeholderType">
                                <b-dropdown block
                                            variant="info"
                                            size="sm"
                                            class="mb-2"
                                >
                                    <template #button-content>
                                        {{ $t('common.placeholder.type.'+placeholderType) }}
                                    </template>
                                    <b-dropdown-item v-for="(placeholder, index) in placeholders"
                                                     @click="insertPlaceholder('zwckeditor_header',placeholder)"
                                                     v-bind:key="index"
                                    >
                                        {{ placeholder }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </div>

                    <zw-ckeditor ref="zwckeditor_header" v-model="form.header"></zw-ckeditor>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t(labelPrefix+'footer')">
                    <b-form-select v-model="form.footer_id"
                                   :options="footerOptions"
                                   class="mb-3"
                                   @change="footerSelected"
                                   size="lg"
                    ></b-form-select>

                    <div class="mb-3">
                        <b-row>
                            <b-col cols="6"
                                   v-for="(placeholders, placeholderType) in getPlaceholders()"
                                   :key="'type_'+placeholderType">
                                <b-dropdown block
                                            variant="info"
                                            size="sm"
                                            class="mb-2"
                                >
                                    <template #button-content>
                                        {{ $t('common.placeholder.type.'+placeholderType) }}
                                    </template>
                                    <b-dropdown-item v-for="(placeholder, index) in placeholders"
                                                     @click="insertPlaceholder('zwckeditor_footer',placeholder)"
                                                     v-bind:key="index"
                                    >
                                        {{ placeholder }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </div>

                    <zw-ckeditor ref="zwckeditor_footer" v-model="form.footer"></zw-ckeditor>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="6">
            </b-col>
            <b-col sm="12" class="text-sm-right text-xs-center">
                <b-button block
                          variant="primary"
                          @click="onSubmit"
                >{{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "@/components/ZwNewDateGroup";

export default {
    name: 'HeadersFooters',
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    props: {
        'offering_id': {
            type: [String, Number],
            required: true
        },
        'type': {
            type: [String],
            required: true
        },
        'afterUpdate': {
            type: [Function],
        },
        'preview': {
            type: [Boolean],
            default: false
        },
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.prepare.label.',
            form: {
                invoice_date: null,
                address_type: 'billing',
                invoice_type: 'procent',
                formality: 'none',
                header: null,
                footer: null,
                options: {},
                header_id: null,
                footer_id: null,
            },
            headerOptions: [],
            footerOptions: [],
            addressTypeOptions: []
        }
    },
    mounted() {
        this.shown()
    },
    watch: {
        header_id: function (val) {
            this.headerSelected(val)
        },
    },
    methods: {
        ...mapGetters('CommonData', ['getFormality', 'getInvoiceTypes']),
        ...mapGetters('Templates', ['getFooters', 'getHeaders','getPlaceholders']),
        ...mapGetters('Invoice', ['getInvoicePrepare']),
        shown() {
            this.loading = true

            this.form.id = this.offering_id
            this.form.type = this.type

            this.addressTypeOptions = [
                {value: 'billing', text: 'Billing address'},
                {value: 'shipping', text: 'Shipping address'},
            ]

            this.headerOptions.push({value: null, text: 'Empty'})
            this.footerOptions.push({value: null, text: 'Empty'})

            const templates = this.$store.dispatch('Templates/fetchTemplates', this.type)

            const invoicePrepare = this.$store.dispatch('Invoice/fetchInvoicePrepare', {
                id: this.offering_id,
                type: this.type
            })

            Promise.all([templates, invoicePrepare])
                .then(() => {
                    if (this.getInvoicePrepare().header_footer) {
                        const templateData = this.getInvoicePrepare().header_footer

                        this.$set(this.form, 'header', templateData.header)
                        this.$set(this.form, 'footer', templateData.footer)
                        this.$set(this.form, 'header_id', templateData.header_id)
                        this.$set(this.form, 'footer_id', templateData.footer_id)
                        this.$set(this.form, 'address_type', templateData.address_type)
                        this.$set(this.form, 'formality', templateData.formality)
                        this.$set(this.form, 'options', templateData.options)

                        this.prepareTemplates()
                    }
                })
                .finally(()=>{
                    this.loading = false
                })
        },
        prepareTemplates() {
            this.getHeaders().forEach(header => {
                this.headerOptions.push({value: header.id, text: header.titel})
                if (!this.form.header_id && header.is_default) {
                    this.$set(this.form, 'header_id', header.id)
                    this.$set(this.form, 'header', header.header)
                }
            })

            this.getFooters().forEach(footer => {
                this.footerOptions.push({value: footer.id, text: footer.titel})
                if (!this.form.footer_id && footer.is_default) {
                    this.$set(this.form, 'footer_id', footer.id)
                    this.$set(this.form, 'footer', footer.footer)
                }
            })
            if (this.preview) {
                this.afterUpdate(this.type)
            }
        },
        headerSelected(value) {
            let header = this.getHeaders().find(header => header.id == value) ?? null

            this.$set(this.form, 'header_id', header ? header.id : null)
            this.$set(this.form, 'header', header ? header.header : '')
        },
        footerSelected(value) {
            let footer = this.getFooters().find(footer => footer.id == value) ?? null

            this.$set(this.form, 'footer_id', footer ? footer.id : null)
            this.$set(this.form, 'footer', footer ? footer.footer : '')
        },
        onSubmit() {
            this.$store.dispatch('Invoice/saveHeaderFooter', this.form)
                .then((response) => {
                    this.afterUpdate()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        insertPlaceholder(key,placeholder) {
            this.$refs[key].$refs.ckeditor.instance.insertText('{{' + placeholder + '}}')
        },
    },
    computed: {
        formalityFormat: function () {
            if (this.form.formality == 'foermlich') {
                return 'Sehr geehrte Nachname'
            } else if (this.form.formality == 'persoenlich') {
                return 'Sehr geehrte Vorname Nachname'
            }

            return ''
        },
    },
}
</script>