<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="contact-persons"
                          base-table="contact-persons"
                          columnsPrefix="contact-persons.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :sticky="false"
                          sticky-header="100%"
                          :tbody-tr-class="rowClass"
                          :actions-list="getActions()"
                >
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>
                            <b-dropdown-item @click="openContactPerson(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="setAsMainSupplier(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="star"/>
                                {{ $t('common.title.main_supplier') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteSupplier(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment";

export default {
    name: 'OfferingSuppliers',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: false,
            tableUniqKey: 'suppliers-list',
        }
    },
    props: {
        offeringId: {
            type: Number,
            required: false
        },
        mainSupplier: {
            type: Number,
        },
        shown: [Function],
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPersonsTable']),
        ctxAdditionalParams() {
            return {
                'supplyId': this.offeringId,
            }
        },
        myProvider(ctx) {
            console.log(moment('26.03.2024', null, null).format('DD.MM.YYYY'))
            return this.commonProviderFetch(ctx, 'ContactPerson/fetchContactPersons', 'getContactPersonsTable');
        },
        afterUpdate() {
            this.refreshTable()
        },
        getActions() {
            return [{
                title: this.$t('offering.button.select_supplier'),
                icon: 'plus',
                click: () => {
                    this.$root.$children[0].openModal('select-supplier-modal', {
                        offeringId: this.offeringId,
                        type: 'provider'
                    }, this.afterUpdate, {width: '800px'})
                }
            }]
        },
        openContactPerson(contactPersonId) {
            this.$root.$children[0].openModal('contact-person-form', {
                id: contactPersonId,
            })
        },
        deleteSupplier(contactPersonId) {
            this.$store.dispatch('Offering/removeSupplier', {
                'id': this.offeringId,
                'contactPersonId': contactPersonId,
            }).then((response) => {
                this.refreshTable()
            });
        },
        setAsMainSupplier(contactPersonId) {
            this.$store.dispatch('Offering/mainSupplier', {
                'id': this.offeringId,
                'contactPersonId': contactPersonId,
            }).then((response) => {
                this.shown()
            });
        },
        rowClass(item) {
            if (item && item.id == this.mainSupplier) {
                return 'tr-warning'
            }
            return ''
        },
    },
}
</script>