<template>
    <div>
        <custom-field v-model="inputVal[field.name]"
                      :field="field"
                      :id="position.id"
                      size="sm"
                      @change="savePosition('custom_'+field.name,position,inputVal[field.name])"
        ></custom-field>
    </div>
</template>

<script>
export default {
    name: 'CustomColumn',
    props: {
        value: [String, Number, Object],
        position: [Object],
        field: [Object],
        savePosition: [Function],
        readOnly: [Boolean]
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>