<template>
    <div>
        <div>
            <b-card no-body>
                <b-tabs lazy pills card vertical>
                    <b-tab v-for="docType in Object.keys(formType =='supply' ? supplyDocuments :documents)"
                           :active="docType==lastType"
                           :key="docType"
                    >
                        <template #title>
                            <font-awesome-icon :icon="documentIcons[docType]"/>
                            {{ $t('offering.button.' + (formType=='supply' ? supplyDocuments[docType] : documents[docType])) }}
                        </template>
                        <b-card-text>
                            <headers-footers :offering_id="offering_id"
                                             :type="docType"
                                             :preview="preview"
                                             :after-update="afterUpdate"
                            ></headers-footers>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import HeadersFooters from "@/modals/offering/offering-blocks/HeadersFooters";

export default {
    name: 'OfferingHeaderFooter',
    components: {HeadersFooters},
    mixins: [commonSave],
    props: {
        'formType': {
            type: [String],
            required: true
        },
        'offering_id': {
            type: [String, Number],
            required: true
        },
        'afterUpdate': {
            type: [Function],
        },
        'lastType': {
            type: [String],
        },
        'preview': {
            type: [Boolean],
            default: false
        },
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.prepare.label.',
            form: {},
            documents: {
                'kva': 'kva',
                'rechnung': 'invoice',
                'lieferschein': 'delivery_note',
                'return_note': 'return_note',
                'product_picker': 'product_picker',
                'confirm': 'confirm',
                'shipping_label': 'shipping_label',
                'return': 'return_label',
                'combine': 'combine',
                'remind': 'remind',
            },
            supplyDocuments: {
                'supply_request': 'supply_request',
                'supply_order': 'supply_order',
            },
            documentIcons: {
                'lieferschein': 'truck',
                'return_note': 'arrow-left',
                'product_picker': 'boxes',
                'shipping_label': ['fab', 'dhl'],
                'return': ['fab', 'dhl'],
                'combine': 'puzzle-piece',
                'kva': 'file-invoice',
                'rechnung': 'file-invoice',
                'confirm': 'info',
                'remind': 'bell',
            },
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        shown() {
            this.loading = false
        },
    },
}
</script>