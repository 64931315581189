<template>
    <div>
        <offering-fulfilment-status v-model="form.offering.fulfilment_status"
                                    :offering-id="form.offering.id"
                                    :readonly="readonly"
                                    :partialReadonly="partialReadonly"
        ></offering-fulfilment-status>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import OfferingFulfilmentStatus from "@/modals/offering/offering-blocks/OfferingFulfilmentStatus";

export default {
    name: 'OfferingFulfilmentInfo',
    components: {
        OfferingFulfilmentStatus,
    },
    mixins: [commonSave],
    props: {
        'form': {
            type: [Object],
            required: true
        },
        'readonly': [Boolean],
        'partialReadonly': [Boolean],
        'afterUpdate': {
            type: [Function],
        }
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.label.',
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        shown() {
            this.loading = true
            this.afterUpdate()
        },
    },
}
</script>