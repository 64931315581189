<template>
    <div>
        <b-row>
            <b-col cols="4">
                <zw-select-group
                    v-model="selected_shipping_method"
                    :options="getShippingMethods()"
                    name="shipping_method"
                    :label-prefix="labelPrefix"
                    @change="updateShippingMethod"
                ></zw-select-group>
            </b-col>
            <b-col cols="4">
                <zw-select-group
                    v-model="form.offering.shipping_status"
                    :options="getShippingStatuses()"
                    name="shipping_status"
                    :label-prefix="labelPrefix"
                    @change="updateShippingStatus"
                ></zw-select-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <table id="log_table" class="table table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('offering.document.date') }}</th>
                        <th>{{ $t('offering.document.name') }}</th>
                        <th>{{ $t('offering.document.type') }}</th>
                        <th>{{ $t('offering.document.actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(document,doc_type) in getOtherDocuments()" :key="'other_'+doc_type"
                        v-if="shippingTypes.includes(doc_type)">
                        <td>
                            <template v-if="document.time">{{
                                    document.time | formatDate('DD.MM.YYYY HH:mm:ss')
                                }}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td>
                            <a v-if="document.url"
                               @click.prevent="$root.openDocument(document.name+'.pdf')"
                               :href="$root.getDocumentUrl(document.name+'.pdf')"
                            >{{ document.name }}.pdf</a>
                            <div v-else>{{ document.name }}</div>
                        </td>
                        <td>{{ doc_type }}</td>
                        <td>
                            <b-button size="sm"
                                      variant="danger"
                                      class="mr-1"
                                      v-if="document.url"
                                      @click="removeFile(document.name+'.pdf')"
                            >
                                <font-awesome-icon icon="trash"/>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'OfferingShippingInfo',
    mixins: [commonSave],
    props: {
        'form': {
            type: [Object],
            required: true
        },
        'afterUpdate': {
            type: [Function],
        }
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.label.',
            selected_shipping_method: '',
            shippingTypes: ['lieferschein', 'shipping_label', 'return']
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonData', ['getShippingMethods', 'getShippingStatuses']),
        ...mapGetters('Offering', ['getDocuments', 'getOtherDocuments']),
        shown() {
            this.loading = true
            this.afterUpdate()
            const documentsFetch = this.$store.dispatch('Offering/fetchDocuments', this.form.offering.id)
            const shippingMethods = this.getShippingMethods()
            const allEqual = arr => arr.every(v => v === arr[0])

            function getKeyByValue(object, value) {
                return Object.keys(object).find(key => object[key] === value);
            }

            let offeringShippingMethods = [];
            this.form.offering.all_positions.forEach((element, index) => {
                if (element.article && element.article.type != 'virtual' && element.article.type != 'shipping') {
                    offeringShippingMethods.push(element.shipping)
                }
            })

            if (offeringShippingMethods.length > 0 && allEqual(offeringShippingMethods)) {
                this.selected_shipping_method = getKeyByValue(shippingMethods, offeringShippingMethods[0]);
            }

            Promise.all([documentsFetch])
                .then(() => {
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        updateShippingMethod(val) {
            this.$store.dispatch('Offering/updateShippingMethod', {
                id: this.form.offering.id,
                shippingMethod: val
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
        updateShippingStatus(val) {
            this.$store.dispatch('Offering/updateShippingStatus', {
                id: this.form.offering.id,
                shipping_status: val
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.$children[0].updateFormValues('offering-modal')
                })
        },
    },
}
</script>