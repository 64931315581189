<template>
    <div>
        <b-row class="mb-3">
            <offering-general-actions v-model="form.documents"
                                      :data="form"
                                      :offering-id="form.offering.id"
                                      :shown="shown"
                                      :form-type="form.offering.type"
            ></offering-general-actions>
        </b-row>
        <b-row>
            <b-col cols="5">
                <table id="log_table" class="table table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('offering.document.date') }}</th>
                        <th>{{ $t('offering.document.name') }}</th>
                        <th>{{ $t('offering.document.type') }}</th>
                        <th>{{ $t('offering.document.actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(document,doc_type) in getOtherDocuments()" :key="'other_'+doc_type">
                        <td>
                            <template v-if="document.time">{{
                                    document.time | formatDate('DD.MM.YYYY HH:mm:ss')
                                }}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td>
                            <a v-if="document.url"
                               @click.prevent="$root.openDocument(document.name+'.pdf')"
                               :href="$root.getDocumentUrl(document.name+'.pdf')"
                            >{{ document.name }}.pdf</a>
                            <div v-else>{{ document.name }}</div>
                        </td>
                        <td>{{ doc_type }}</td>
                        <td>
                            <b-button size="sm"
                                      variant="danger"
                                      class="mr-1"
                                      v-if="document.url"
                                      @click="removeFile(document.name+'.pdf')"
                            >
                                <font-awesome-icon icon="trash"/>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
            <b-col cols="7">
                <table id="log_table" class="table table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('offering.document.date') }}</th>
                        <th>{{ $t('offering.document.name') }}</th>
                        <th>{{ $t('offering.document.type') }}</th>
                        <th>{{ $t('offering.document.creator') }}</th>
                        <th>{{ $t('offering.document.mails') }}</th>
                        <th>{{ $t('offering.document.actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="document in getDocuments()" :key="document.id">
                        <td>
                            {{ document.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                        </td>
                        <td>
                            <a v-if="document.file_exist"
                               @click.prevent="$root.openDocument(document.name)"
                               :href="$root.getDocumentUrl(document.name)"
                            >{{ document.name }}</a>
                            <div v-else>{{ document.name }}</div>
                        </td>
                        <td>{{ document.doc_type }}</td>
                        <td>{{ document.user ? document.user.email : '-' }}</td>
                        <td>
                            <div v-for="mail in document.mails"
                                 :key="mail.id"
                            >
                                {{ mail.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                                <span v-if="mail.is_send">
                            ({{ $t('common.label.send') }})
                        </span>
                                <span v-else>
                            ({{ $t('common.label.not_send') }})
                        </span>
                                <template v-if="mail.message_id">
                                    <b-button
                                        @click="$root.$children[0].openModal('show-email-message-modal', {messageId: mail.message_id}, null, {width: '800px'})"
                                        size="sm"
                                        variant="info"
                                        class="mr-2"
                                    >
                                        <font-awesome-icon icon="eye"/>
                                    </b-button>
                                </template>
                            </div>
                        </td>
                        <td>
                            <b-button @click="sendMail(document)"
                                      size="sm"
                                      variant="warning"
                                      class="mr-1"
                                      v-if="document.file_exist"
                            >
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-button>

                            <b-button size="sm"
                                      variant="danger"
                                      class="mr-1"
                                      v-if="document.file_exist && document.doc_type!='rechnung'"
                                      @click="removeFile(document.name)"
                            >
                                <font-awesome-icon class="mr-2" icon="trash"/>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>

        <div class="pt-2 pb-4">
            <b-row>
                <b-col>
                    <contracts
                        :customerId="form.offering.contact_person.kunden_Id"
                        :offeringId="form.offering.id"
                    />
                </b-col>
            </b-row>
        </div>
        <div>
            <b-row>
                <b-col>
                    <h3>
                        {{ $t('offering.tab.attachments') }}
                    </h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('sales-attachment-modal', {
                            offeringId:form.offering.id,
                            customerId:form.offering.contact_person.kunden_Id,
                            }, shown, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-table-simple striped id="attachments_table">
                <b-thead head-variant="info">
                    <b-th>{{ $t('offering.attachments.label.id') }}</b-th>
                    <b-th>{{ $t('offering.attachments.label.name') }}</b-th>
                    <b-th>{{ $t('offering.attachments.label.date') }}</b-th>
                    <b-th>{{ $t('offering.attachments.label.docs') }}</b-th>
                    <b-th>{{ $t('offering.attachments.label.actions') }}</b-th>
                </b-thead>
                <b-tr v-for="attachment in attachments" :key="attachment.id">
                    <b-td>{{ attachment.id }}</b-td>
                    <b-td>{{ attachment.name.replace(/^.*[\\\/]/, '') }}</b-td>
                    <b-td>{{ attachment.updated_at | formatDate('DD.MM.YYYY') }}</b-td>
                    <b-td>
                        <multiselect
                            v-if="attachment.parent_id && attachment.name.endsWith('.pdf')"
                            v-model="attachment.docs_option"
                            :options="getDocumentTypes()"
                            :multiple="true"
                            @input="changeDocs($event, attachment.id)"
                        >
                        </multiselect>
                    </b-td>
                    <b-td>
                        <b-button
                            @click="$root.downloadDocument(attachment.id,'media')"
                            size="sm"
                            variant="primary"
                            class="mr-2"
                            :title="$t('common.title.download')"
                        >
                            <font-awesome-icon icon="download"/>
                        </b-button>

                        <b-button @click="deleteAttachment(attachment.id)"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </b-td>
                </b-tr>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import OfferingGeneralActions from "@/modals/offering/offering-blocks/OfferingGeneralActions";
import Contracts from "@/tables/Contracts";

export default {
    name: 'OfferingDocuments',
    components: {OfferingGeneralActions, Contracts},
    mixins: [commonSave],
    props: {
        'form': {
            type: [Object],
            required: true
        },
        'afterUpdate': {
            type: [Function],
        }
    },
    data() {
        return {
            attachments: [],
            loading: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Offering', ['getDocuments', 'getAttachments', 'getOtherDocuments']),
        ...mapGetters('CommonData', ['getDocumentTypes']),
        shown() {
            this.loading = true
            this.afterUpdate()
            const documentsFetch = this.$store.dispatch('Offering/fetchDocuments', this.form.offering.id)

            Promise.all([documentsFetch])
                .then(() => {
                    this.attachments = this.getAttachments()
                    this.attachments.forEach(attachment => {
                        attachment.docs_option = [];
                        if (attachment.options && attachment.options.docs) {
                            attachment.docs_option = attachment.options.docs
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        sendMail(document) {
            this.$root.$children[0].openModal('email-modal', {documentId: document.id}, this.shown, {})
        },
        removeFile(name) {
            this.$removeConfirm('Offering/deleteDocument', name, this.shown)
        },
        changeDocs: function (event, id) {
            Promise.all([this.$store.dispatch('Sales/setDocsAttachment', {docs: event, id: id})]).then(() => {
                this.shown()
            })
        },
        deleteAttachment(id) {
            Promise.all([this.$store.dispatch('Sales/deleteAttachment', id)]).then(() => {
                this.shown()
            })
        },
    },
}
</script>