<template>
    <b-row>
        <b-col cols="6">
            <zw-events :events="getEvents()"></zw-events>
        </b-col>
        <b-col cols="6">
            <zw-events :events="getEventsTiming()"></zw-events>
        </b-col>
    </b-row>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'OfferingEvents',
    mixins: [commonSave],
    props: {
        'offering_id': {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        ...mapGetters('Offering', ['getEvents', 'getEventsTiming']),
    },
    mounted() {
        const eventsFetch = this.$store.dispatch('Offering/fetchEvents', this.offering_id)
        const eventsTimingFetch = this.$store.dispatch('Offering/fetchEventsTiming', this.offering_id)
    }
}
</script>

<style lang="scss">
.timeline {
    .vertical-timeline {
        width: 100%;
        position: relative;
        padding: 1.5rem 0 1rem;
    }

    .vertical-timeline::before {
        content: '';
        position: absolute;
        top: 0;
        left: 130px;
        height: 100%;
        width: 4px;
        background: #e9ecef;
        border-radius: .25rem;
    }

    .vertical-timeline-element {
        position: relative;
        margin: 0 0 1rem;
    }

    .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
        visibility: visible;
        animation: cd-bounce-1 .8s;
    }

    .vertical-timeline-element-icon {
        position: absolute;
        top: 0;
        left: 123px;
    }

    .vertical-timeline-element-icon .badge-dot-xl {
        box-shadow: 0 0 0 5px #fff;
    }

    .badge-dot-xl {
        width: 18px;
        height: 18px;
        position: relative;
    }

    .badge:empty {
        display: none;
    }


    .badge-dot-xl::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: .25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -5px 0 0 -5px;
        background: #fff;
    }

    .vertical-timeline-element-content {
        position: relative;
        margin-left: 145px;
        font-size: .8rem;
    }

    .vertical-timeline-element-content .timeline-title {
        font-size: .8rem;
        margin: 0 0 .5rem;
        padding: 2px 0 0;
    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
        display: block;
        position: absolute;
        left: -109px;
        top: 0;
        padding-right: 10px;
        text-align: right;
        font-weight: bold;
        color: #adb5bd;
        font-size: .7619rem;
        width: 92px;
        white-space: nowrap;
    }

    .vertical-timeline-element-content .vertical-timeline-element-time {
        display: block;
        position: absolute;
        left: -109px;
        top: 20px;
        width: 92px;
        padding-right: 10px;
        text-align: right;
        color: #adb5bd;
        font-size: .7619rem;
        white-space: nowrap;
    }

    .vertical-timeline-element-content:after {
        content: "";
        display: table;
        clear: both;
    }
}
</style>