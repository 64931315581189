<template>
    <div>
        <zw-table ref="table"
                  tableName="offering_history"
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :selectedSource="selectedSource"
                  :actions-list="getActions()"
                  :sticky="false"
                  sticky-header="100%"
        >
            <template #cell(actions)="row">
                <b-button size="sm"
                          @click="$root.$children[0].openModal('json-modal', {data: row.item.changes})"
                          variant="info"
                >
                    <font-awesome-icon icon="eye"/>
                </b-button>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'OfferingHistory',
    mixins: [commonSave, commonTable],
    props: {
        'offering_id': {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Offering', ['getHistoryTable']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Offering/fetchHistoryTable', 'getHistoryTable');
        },
        ctxBaseParams() {
            return {
                'offering_id': this.offering_id
            }
        },
        getActions() {
            return []
        },
    },
}
</script>