<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <validation-observer tag="div" ref="mainobserver" v-if="mainFieldExist">
            <b-card class="mt-3 zero_padding_card_body"
                    v-if="inputVal.offering.customFields"
            >
                <b-card-header header-tag="header" class="p-0 m-0" role="tab">
                    <b-button block v-b-toggle="'mainfields'" class="btn-light">
                        {{ $t('common.group.custom_fields') }}
                    </b-button>
                </b-card-header>
                <b-collapse :id="'mainfields'"
                            :visible="!mainFiledValid"
                            :accordion="'mainfields'"
                            role="tabpanel">

                    <b-card-text class="p-3">
                        <b-row>
                            <b-col v-for="field in inputVal.offering.customFields"
                                   :key="field.name"
                                   v-if="field.options.group!='hidden' && field.options.main_field == 1"
                                   sm="3"
                            >
                                <custom-field v-model="inputVal.offering.customValues[field.name]"
                                              :model="inputVal.offering"
                                              :minDate="field.options.actual_dates == 1 ? currentDate() : ''"
                                              :readonly="field.options.readonly == 1 ? true : false"
                                              :isRequired="field.options.required == 1 ? true : false"
                                              :field="field"></custom-field>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-button block
                                          variant="primary"
                                          @click="saveMainCustomFields()"
                                >{{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-collapse>
            </b-card>
        </validation-observer>
        <div style="position: relative;">
            <b-overlay :show="!mainFiledValid" no-wrap></b-overlay>
            <b-row v-if="inputVal.offering">
                <offering-general-status v-model="inputVal.offering.status"
                                         :offering-id="inputVal.offering.id"
                                         :readonly="readonly"
                                         :partialReadonly="partialReadonly"
                                         v-if="formType!='supply'"
                ></offering-general-status>

                <b-col cols="5" v-if="formType=='supply'">
                    <b-form inline>
                        <b-row>
                            <b-col cols="10">
                                <label class="mr-sm-2 d-none d-lg-inline">{{ $t('offering.label.status') }}</label>
                                <zw-select-group v-model="inputVal.entity_status_id"
                                                 :options="entityStatuses('supply')"
                                                 name="supply_status"
                                                 disable-label
                                                 :readonly="readonly"
                                                 @change="updateEntityStatus"
                                                 style="max-width: 250px; display: inline-block;"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>

                <offering-general-actions v-model="inputVal.documents"
                                          :data="inputVal"
                                          :offering-id="inputVal.offering.id"
                                          :offering="inputVal.offering"
                                          :shown="shown"
                                          :readonly="readonly"
                                          :partialReadonly="partialReadonly"
                                          :show-preview="showPreview"
                                          :refreshTable="refreshTable"
                                          :form-type="formType"
                ></offering-general-actions>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex" v-if="!readonly">
                    <b-form inline>
                        <b-button
                            class="mr-1"
                            @click="sidebar=!sidebar"
                        >
                            <font-awesome-icon :icon="sidebar ? 'caret-square-right' : 'caret-square-down'"/>
                        </b-button>

                        <template v-if="!$root.simpleMode">
                            <b-button variant="success"
                                      class="mr-1"
                                      :title="$t('common.button.add_category')"
                                      @click="createCategory"
                            >
                                <font-awesome-icon size="sm" icon="plus"/>
                                {{ $t('common.button.add_category') }}
                            </b-button>

                            <b-button variant="success"
                                      class="mr-1"
                                      :title="$t('common.button.add_product')"
                                      @click="createProduct"
                            >
                                <font-awesome-icon icon="plus"/>
                                {{ $t('common.button.add_product') }}
                            </b-button>
                        </template>
                        <search-field v-model="search"
                                      class="mt-1"
                                      name="search"
                                      :label-prefix="labelPrefix"
                                      :url="articleSearchUrl"
                                      disable-label
                                      :placeholder="$t('common.label.search')"
                                      clean
                                      @input-object="articleSelected"
                        ></search-field>
                    </b-form>
                </b-col>
            </b-row>
            <div style="/*zoom: 1.332;*/">
                <div>
                    <b-row>
                        <b-col cols="3" v-if="sidebar && !readonly">
                            <b-row>
                                <b-col class="text-right">
                                    <b-button variant="warning"
                                              class="mb-3 mr-2"
                                              size="sm"
                                              @click="refreshTree"
                                    >
                                        <font-awesome-icon icon="sync"/>
                                    </b-button>
                                    <b-button variant="primary"
                                              class="mb-3 mr-2"
                                              size="sm"
                                              @click="$root.$children[0].openModal('article-modal', {id:0}, refreshTree)"
                                    >
                                        <font-awesome-icon icon="plus"/>
                                    </b-button>
                                    <b-button variant="primary"
                                              class="mb-3 mr-2"
                                              size="sm"
                                              :disabled="!selectedTreeOption || nodeIsCategory"
                                              @click="$root.$children[0].openModal('article-modal', {id: selectedTreeNode.id},refreshTree)"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>
                                    <b-button variant="success"
                                              class="mb-3"
                                              size="sm"
                                              :disabled="!selectedTreeOption || nodeIsCategory"
                                              @click="moveFromTree"
                                    >
                                        <font-awesome-icon :icon="['fas', 'arrow-circle-right']"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <treeselect v-model="selectedTreeOption"
                                                v-if="showTree"
                                                ref="treeSelect"
                                                :options="treeOptions"
                                                :defaultOptions="treeOptions"
                                                :load-options="loadOptions"
                                                :async="false"
                                                :always-open="true"
                                                value-consists-of="LEAF_PRIORITY"
                                                @search-change="searchOptions"
                                                @select="optionSelected"
                                                @deselect="()=>{selectedTreeNode = null}"
                                    ></treeselect>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col>
                            <b-row style="overflow-x: auto;">
                                <b-col>
                                    <table class="zoomed-table">
                                        <b-thead head-variant="info">
                                            <b-th style="min-width: 30px">&nbsp;</b-th>
                                            <b-th style="min-width: 50px">&nbsp;</b-th>
                                            <b-th style="min-width: 30px">&nbsp;</b-th>
                                            <b-th class="position-column"
                                                  v-for="field in fields"
                                                  :key="field.key"
                                                  :style="columnsWidth[field.key] ? ('min-width:'+columnsWidth[field.key]+';max-width:'+columnsWidth[field.key]) : ''"
                                            >
                                    <span style="white-space: nowrap;"
                                          v-for="group in field.groups">[{{ group }}]</span>
                                                <span v-if="field.title"
                                                      style="white-space: nowrap;"
                                                >{{ field.title }}</span>
                                                <span v-else
                                                      :data-key="field.key"
                                                      style="white-space: nowrap;"
                                                >{{ $t(field.translation) }}</span>

                                                <input type="hidden" name="name" :value="field.key">
                                                <div
                                                    @mousedown="onColumnDown"
                                                    style="top:0;right: 0;bottom: 0;width: 5px;position: absolute;cursor: col-resize;border-left: dotted 1px gray;border-right: dotted 1px gray;margin-top: 2px;margin-bottom: 2px;">
                                                    &nbsp;
                                                </div>
                                            </b-th>
                                        </b-thead>
                                    </table>
                                    <nested-position :selected-category="selectedCategory"
                                                     :items="tableData"
                                                     :fields="fields"
                                                     :refresh-table="refreshTable"
                                                     :render-table="renderTable"
                                                     :columns-width="columnsWidth"
                                                     :read-only="readonly"
                                                     :partialReadonly="partialReadonly"
                                                     :root="true"
                                                     :form-type="formType"
                                                     @dragged="dragged"
                                                     @category-selected="categorySelected"
                                    ></nested-position>
                                    <table v-if="formType != 'return' && !readonly" class="zoomed-table fake-position">
                                        <b-tbody head-variant="info">
                                            <b-td style="min-width: 30px">&nbsp;</b-td>
                                            <b-td style="min-width: 50px">
                                                <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                                            </b-td>
                                            <b-td class="text-center" style="min-width: 30px">{{
                                                    tableData.length + 1
                                                }}
                                            </b-td>
                                            <b-td class="position-column"
                                                  v-for="field in fields"
                                                  :key="field.key"
                                                  :style="columnsWidth[field.key] ? ('min-width:'+columnsWidth[field.key]+';max-width:'+columnsWidth[field.key]) : ''"
                                            >
                                                <template v-if="field.key == 'sku'">
                                                    <search-field v-model="search"
                                                                  class="mt-2 position-search-field"
                                                                  name="search"
                                                                  :label-prefix="labelPrefix"
                                                                  :url="articleSkuSearchUrl"
                                                                  disable-label
                                                                  :clean="true"
                                                                  @input-object="(article, with_category)=>{articleSelected(article, with_category, true,'searchSku')}"
                                                                  ref="searchSku"
                                                                  :minMatchingChars="1"
                                                    ></search-field>
                                                </template>
                                            </b-td>
                                        </b-tbody>
                                    </table>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="8"></b-col>
                                <b-col>
                                    <table class="zoomed-table" style="width:100%;">
                                        <b-tfoot>
                                            <th>
                                                <b-row>
                                                    <b-col class="text-right">{{
                                                            $t('offering.totals.total_purchase_price')
                                                        }}
                                                    </b-col>
                                                    <b-col>{{ totals.total_purchase_price | priceFormat }}{{
                                                            currency
                                                        }}
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right">{{ $t('offering.totals.profit') }}</b-col>
                                                    <b-col>{{ totals.profit | priceFormat }}{{ currency }}</b-col>
                                                </b-row>
                                                <b-row v-for="(amount,vat) in totals.vats"
                                                       :key="vat"
                                                >
                                                    <b-col class="text-right">{{ $t('offering.totals.vat') }} ({{
                                                            vat
                                                        }}%)
                                                    </b-col>
                                                    <b-col>{{ amount | priceFormat }}{{ currency }}</b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right">{{
                                                            $t('offering.totals.return_brutto')
                                                        }}
                                                    </b-col>
                                                    <b-col>{{ inputVal.offering.return_brutto | priceFormat }}{{
                                                            currency
                                                        }}
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right">{{ $t('offering.totals.tax') }}</b-col>
                                                    <b-col>{{ totals.total_vat | priceFormat }}{{ currency }}</b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right">{{ $t('offering.totals.total') }}</b-col>
                                                    <b-col>{{ totals.total | priceFormat }}{{ currency }}</b-col>
                                                </b-row>
                                                <b-row v-if="!inputVal.valid_invoice_price">
                                                    <b-col class="text-center text-danger">
                                                        {{ $t('offering.totals.invalid_invoice_price') }}
                                                    </b-col>
                                                </b-row>
                                            </th>
                                        </b-tfoot>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <custom-slot id="offering_after_positions" :base="getThis()"></custom-slot>

            <b-card class="mt-3 zero_padding_card_body" v-if="!readonly">
                <b-card-header header-tag="header" class="p-0 m-0" role="tab">
                    <b-button block v-b-toggle="'discounts'" class="btn-light">
                        {{ $t('common.group.discount') }}
                    </b-button>
                </b-card-header>
                <b-collapse :id="'discounts'"
                            :visible="false"
                            :accordion="'discounts'"
                            role="tabpanel">
                    <b-card-text class="p-3">
                        <b-row>
                            <b-col sm="3">
                                <zw-input-group :name="'discount_'+discount.discountType"
                                                v-model="discount.discount"
                                                type="number"
                                                min="0"
                                                label-prefix="columns.position."
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-select-group v-model="discount.discountType"
                                                 :options="discountTypeOptions()"
                                                 name="discountType"
                                                 label-prefix="columns.position."
                                ></zw-select-group>
                            </b-col>
                            <b-col sm="3">
                                <b-button
                                    class="mt-4"
                                    variant="warning"
                                    @click="appendDiscount()"
                                >
                                    <template v-if="discount.discountType == 'kva_fix'">
                                        {{ $t('offering.button.append_discount_kva') }}
                                    </template>
                                    <template v-else>
                                        {{ $t('offering.button.append_discount') }}
                                    </template>


                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-collapse>
            </b-card>

            <validation-observer tag="div" ref="observer">
                <custom-fields-block v-model="inputVal.offering.customValues"
                                     :fields="inputVal.offering.customFields"
                                     model="customer"
                                     :form="inputVal.offering"
                />

                <b-row>
                    <b-col sm="3">
                        <b-button block
                                  variant="primary"
                                  @click="saveCustomFields(false)"
                        >{{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                    <b-col sm="3">
                        <b-button block
                                  variant="info"
                                  @click="saveCustomFields(true)"
                        >{{ $t('common.button.save_and_stay') }}
                        </b-button>
                    </b-col>
                    <b-col sm="3"></b-col>
                    <b-col sm="3">
                    </b-col>
                </b-row>
            </validation-observer>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable";
import CountColumn from "@/modals/offering/offering-blocks/positions/countColumn";
import {LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH, LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
import moment from 'moment'
import CustomFieldsBlock from "@/components/CustomFieldsBlock";
import OfferingGeneralActions from "@/modals/offering/offering-blocks/OfferingGeneralActions";
import OfferingGeneralStatus from "@/modals/offering/offering-blocks/OfferingGeneralStatus";
import NestedPosition from "@/modals/offering/offering-blocks/positions/NestedPosition";

export default {
    name: 'OfferingGeneral',
    components: {
        NestedPosition,
        OfferingGeneralStatus,
        OfferingGeneralActions,
        CustomFieldsBlock,
        CountColumn,
        Draggable
    },
    mixins: [commonSave],
    props: {
        'value': [Object],
        'formType': [String],
        'readonly': [Boolean],
        'partialReadonly': [Boolean],
        'shown': [Function],
        'showPreview': [Function],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'offering.label.',
            dragging: false,
            search: null,
            sidebar: false,
            supplyStatus: null,
            mainFiledValid: true,
            mainFieldExist: false,
            articleSearchUrl: window.apiUrl + '/articles/search',
            articleSkuSearchUrl: window.apiUrl + '/articles/search/sku',
            fields: [],
            allItems: [],
            tableData: [],
            columnsWidth: {
                actions: '170px',
                name: '200px',
                shipping: '200px',
                description: '200px',
                custom_pieces: '400px',
            },
            selectedCategory: null,
            categoriesOrder: {},
            productsOrder: {},
            categoriesParents: {},
            productsParents: {},
            selectedTreeOption: null,
            selectedTreeNode: null,
            showTree: true,
            treeOptions: null,
            discount: {
                discount: 0,
                discountType: 'fest',
            },
            thElm: null,
            startOffset: null,
        }
    },
    mounted() {
        // if (this.formType == 'supply') {
        //     this.articleSearchUrl = window.apiUrl + '/articles/supply-search';
        //     this.articleSkuSearchUrl = window.apiUrl + '/articles/supply-search/sku';
        // }
        this.refreshTable()
        this.initResize()
        this.checkMainFiledsExist()
        this.checkMainFiledValid()
    },
    methods: {
        ...mapGetters('CommonData', ['getUnits', 'getWeightUnits', 'getDiscountTypes', 'getMe', 'getEntityStatuses']),
        ...mapGetters('Positions', ['getPositionsTable', 'getReturnPositionsTable']),
        getThis() {
            return this
        },
        checkMainFiledsExist() {
            this.mainFieldExist = false;
            Object.entries(this.inputVal.offering.customFields).forEach(([key, value]) => {
                if (value.options.main_field == 1) {
                    this.mainFieldExist = true;
                }
            });
        },
        refreshTable(callback = null) {
            this.shown()
            let params = {
                filter: {
                    tableUniqKey: ['supply', 'return'].includes(this.formType) ? 'positions' + this.formType : null,
                },
                offering_id: this.inputVal.offering.id,
                currentPage: 1,
                perPage: 1000,
            }
            this.loading = true
            this.$store.dispatch(this.formType == 'return' ? 'Positions/fetchReturnPositions' : 'Positions/fetchPositions', params)
                .then(() => {
                    const tableData = this.formType == 'return' ? this.getReturnPositionsTable() : this.getPositionsTable()

                    this.fields = tableData.columns
                    this.allItems = tableData.data
                    this.tableData = tableData.tree
                    this.selectedCategory = null

                    this.fields.forEach(field => {
                        this.$set(this.columnsWidth, field.key, field.width)
                    })

                    // this.showPreview(this.inputVal.offering.id)
                })
                .finally(() => {
                    this.loading = false
                    if (callback) {
                        callback()
                    }
                })
        },
        checkMainFiledValid() {
            setTimeout(() => {
                if (typeof this.$refs['mainobserver'] != "undefined") {
                    this.$refs['mainobserver'].validate().then(valid => {
                        this.mainFiledValid = valid;
                    })
                } else {
                    this.mainFiledValid = true;
                }
            }, 500);
        },
        renderTable() {
            const tableData = this.formType == 'return' ? this.getReturnPositionsTable() : this.getPositionsTable()

            this.fields = tableData.columns
            this.tableData = tableData.tree
            this.showPreview(this.inputVal.offering.id)
        },
        articleSelected(article, with_category = false, return_focus = false, refs = '') {
            // if (article.appendUrl) {
            //     window.axios.post(window.apiUrl + article.appendUrl, {
            //         offeringId: this.inputVal.offering.id,
            //         articleId: article.id,
            //         category_id: this.selectedCategory,
            //         with_category: with_category
            //     })
            //         .then(() => {
            //             this.$root.showMessage(
            //                 this.$t('common.form.title.success'),
            //                 this.$t('common.toasts.success_appended'),
            //                 'success'
            //             )
            //         })
            //         .finally(() => {
            //             this.loading = false
            //             this.refreshTable()
            //             if (return_focus && refs) {
            //                 this.$refs[refs][0].$refs.typeahead.$refs.input.focus()
            //             }
            //         })
            // } else {
            this.$store.dispatch('Offering/appendOfferingPosition', {
                offeringId: this.inputVal.offering.id,
                articleId: article.id,
                category_id: this.selectedCategory,
                with_category: with_category
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_appended'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                    this.refreshTable(() => {
                        let countInputs = document.querySelectorAll('input[name="count"]')
                        if (countInputs.length) {
                            countInputs[countInputs.length - 1].focus()
                            countInputs[countInputs.length - 1].select()
                        }
                    })
                    if (return_focus && refs) {
                        this.$refs[refs][0].$refs.typeahead.$refs.input.focus()
                    }
                })
            // }

            this.search = null
        },
        createProduct() {
            if (this.inputVal.custom_article_id) {
                this.$store.dispatch('Offering/appendOfferingPosition', {
                    offeringId: this.inputVal.offering.id,
                    articleId: this.inputVal.custom_article_id,
                    category_id: this.selectedCategory
                })
                    .then(() => {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_appended'),
                            'success'
                        )
                    })
                    .finally(() => {
                        this.loading = false
                        this.refreshTable()
                    })
            } else {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.custom_product_not_found'),
                    'danger'
                )
            }

        },
        createCategory() {
            this.$store.dispatch('Positions/createCategory', {
                offering_id: this.inputVal.offering.id,
                parent_id: this.selectedCategory,
            })
                .then((response) => {

                })
                .finally(() => {
                    this.loading = false
                    this.refreshTable()
                })
        },
        calcOrders(items, parentId) {
            items.forEach((item, index) => {
                if (item.type == 'category') {
                    this.categoriesOrder[item.data.id] = index
                    this.categoriesParents[item.data.id] = parentId
                } else {
                    this.productsOrder[item.data.id] = index
                    this.productsParents[item.data.id] = parentId
                }
                this.calcOrders(item.items, item.data.id)
            })
        },
        dragged() {
            this.categoriesOrder = {}
            this.productsOrder = {}
            this.categoriesParents = {}
            this.productsParents = {}
            this.calcOrders(this.tableData, null)

            this.$store.dispatch('Positions/reorderPositions', {
                categories: this.categoriesOrder,
                products: this.productsOrder,
                category_parents: this.categoriesParents,
                product_parents: this.productsParents,
            })
                .then(() => {

                })
                .finally(() => {
                    this.loading = false
                    this.refreshTable()
                })
        },
        categorySelected(val) {
            if (val) {
                this.selectedCategory = val
            } else {
                this.selectedCategory = null
            }
        },
        searchOptions(val) {
            this.$store.dispatch('Articles/searchArticlesTree', val)
                .then((result) => {
                    this.treeOptions = result.data
                })
        },
        loadOptions({action, parentNode, callback, searchQuery}) {
            if (action === LOAD_ROOT_OPTIONS) {
                this.$store.dispatch('Articles/fetchArticlesTree', null)
                    .then((result) => {
                        this.treeOptions = result.data
                        callback()
                    })
            }
            if (action === LOAD_CHILDREN_OPTIONS) {
                this.$store.dispatch('Articles/fetchArticlesTree', parentNode.id)
                    .then((result) => {
                        parentNode.children = result.data
                        callback(null, this.treeOptions)
                    })
            }
            // if (action === ASYNC_SEARCH) {
            //     this.$store.dispatch('Articles/searchArticlesTree', searchQuery)
            //         .then((result) => {
            //             callback(null, result.data)
            //         })
            // }
        },
        optionSelected(node) {
            this.selectedTreeNode = node
        },
        refreshTree() {
            this.treeOptions = null
            this.showTree = false
            this.selectedTreeOption = null
            this.selectedTreeNode = null
            this.$nextTick(function () {
                this.showTree = true
            })
        },
        moveFromTree() {
            if (this.nodeIsCategory) {
            } else {
                this.articleSelected({id: this.selectedTreeNode.id}, true)
            }
        },
        discountTypeOptions: function () {
            let options = []
            this.getDiscountTypes().forEach(key => {
                options.push({
                    value: key,
                    text: this.$t('offering.position.discount.type.' + key),
                })
            })
            return options
        },
        appendDiscount() {
            this.$store.dispatch('Offering/appendDiscount', {
                id: this.inputVal.offering.id,
                discount: this.discount.discount,
                type: this.discount.discountType,
            })
                .finally(() => {
                    this.discount.discount = 0
                    this.refreshTable()
                })
        },
        saveMainCustomFields() {
            this.$refs['mainobserver'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Offering/updateOffering', {
                        id: this.inputVal.offering.id,
                        customValues: this.inputVal.offering.customValues,
                    })
                        .finally(() => {
                            this.refreshTable()
                        })

                    this.mainFiledValid = valid;
                } else {
                    this.showValidationError()
                }
            })
        },
        saveCustomFields(stay = false) {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Offering/updateOffering', {
                        id: this.inputVal.offering.id,
                        customValues: this.inputVal.offering.customValues,
                    })
                        .finally(() => {
                            if (stay == false) {
                                this.$root.$children[0].closeModal(this.$parent.$parent.$parent.$parent.$refs['sidebar'])
                            } else {
                                this.$root.$children[0].updateFormValues('offering-modal')
                                this.refreshTable()
                            }
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        onColumnDown(event) {
            this.thElm = event.target.parentElement;
            this.startOffset = event.target.parentElement.offsetWidth - event.pageX;
            event.preventDefault()
        },
        initResize() {
            document.addEventListener('mousemove', (e) => {
                if (this.thElm) {
                    this.thElm.style.minWidth = this.startOffset + e.pageX + 'px';
                    this.thElm.style.maxWidth = this.startOffset + e.pageX + 'px';
                }
            });

            document.addEventListener('mouseup', (e) => {
                if (this.thElm) {
                    let name = this.thElm.querySelector('input[name="name"]').value

                    this.$set(this.columnsWidth, name, this.thElm.style.minWidth)
                    this.saveColumnWidth('positions', name, this.thElm.style.minWidth)
                    e.preventDefault()
                }
                this.thElm = undefined;
            });
        },
        saveColumnWidth(table, column, width) {
            axios.post(window.apiUrl + '/save-table-column-width', {
                table,
                column,
                width: width,
            }, {'skip_loading': true})
        },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
        entityStatuses(type) {
            const entityStatuses = this.getEntityStatuses()[type] || []
            let list = {}
            entityStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        },
        updateEntityStatus() {
            this.$store.dispatch('Offering/updateSupplyStatus', {
                id: this.inputVal.offering.id,
                status_id: this.inputVal.entity_status_id
            })
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        nodeIsCategory: function () {
            return this.selectedTreeNode ? Object.keys(this.selectedTreeNode).includes('children') : null
        },
        totals: function () {
            let totalPurchasePrice = 0
            let totalPriceWithDiscount = 0
            let vatSums = {}
            let totalVat = 0
            this.allItems.forEach(position => {
                if (this.formType == 'return') {
                    let count = position.return_count || 0
                    let single_position_price = (position.price_with_discount / (position.count ? position.count : 1))
                    totalPurchasePrice += count * position.ek_price
                    if (count) {
                        let positionVatSum = position.vat_amount ? position.vat_amount : single_position_price * position.vat / 100

                        if (vatSums[position.vat]) {
                            vatSums[position.vat] += positionVatSum
                        } else {
                            vatSums[position.vat] = positionVatSum
                        }
                    }
                    totalPriceWithDiscount += single_position_price * count
                } else {
                    if (!position.intern) {
                        totalPurchasePrice += position.count * position.ek_price

                        let positionVatSum = position.vat_amount ? position.vat_amount : position.price_with_discount * position.vat / 100

                        if (vatSums[position.vat]) {
                            vatSums[position.vat] += positionVatSum
                        } else {
                            vatSums[position.vat] = positionVatSum
                        }
                        totalVat += positionVatSum
                    }

                    totalPriceWithDiscount += position.price_with_discount
                }
            })

            const total = (totalPriceWithDiscount + totalVat).toFixed(2)
            const vat = totalVat.toFixed(2)
            const netto = total - vat

            return {
                'total_purchase_price': totalPurchasePrice,
                'total_price_with_discount': netto,
                'profit': netto - totalPurchasePrice,
                'vats': vatSums,
                'total_vat': vat,
                'total': total,
            }
        },
        currency: function () {
            return this.inputVal.offering ? this.inputVal.offering.waehrung_short : '&euro;'
        },
    },
}
</script>

<style>
.fake-position {
    border: solid 1px gray;
    border-right: none;
    border-top: none;
}

.fake-position .vbt-autcomplete-list {
    position: relative;
    overflow-y: visible;
}

.vbt-autcomplete-list {
    min-width: 400px;
}

.vbt-autcomplete-list:not(:has(div)) {
    display: none;
}

td.position-column {
    background-color: white;
}

th.position-column {
    position: relative;
}
</style>